import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import * as C from '../components'
import IntroBundles from '../shop/IntroBundles'
import Bundles from '../shop/Bundles'

interface Props {
  bundles: any
}

const Capsules: React.SFC<Props> = bundles => (
  <C.App>
    <IntroBundles />
    <Bundles bundles={bundles} />
    <C.JoinCommunity />
  </C.App>
)

export const CapsulesQ = graphql`
  query {
    allContentfulCatalog {
      edges {
        node {
          bundles {
            ...BundleFragment
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={CapsulesQ}
    render={data => (
      <Capsules bundles={data.allContentfulCatalog.edges[0].node.bundles} />
    )}
  />
)
