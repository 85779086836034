import * as React from 'react'
import * as C from '../components'
import * as S from '../styles'
import track from '../track'

interface Props {
  bundles: any
}

const Bundles: React.SFC<Props> = ({ bundles }) => {
  const bundleArray = bundles.bundles
  const [filter, setFilter] = React.useState('all')

  const filtered =
    filter === 'all'
      ? bundleArray
      : bundleArray.filter(bundle => bundle.vintage.name === filter)

  const vintageYears = bundleArray.map(bundle => bundle.vintage.name)

  const uniqueVintageYears = vintageYears.reduce(
    (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
    []
  )

  const handleFilterChange = value => {
    setFilter(value)
    track('Bundle List Filtered', {
      filteredBy: value,
    })
  }

  return (
    <S.Section className="sub center" style={{ paddingTop: 45 }}>
      <S.Wrapper className="small">
        <div>
          <S.Grid style={{ justifyContent: 'flex-start' }}>
            {filtered.map(bundle => (
              <S.GridItem
                key={bundle.id}
                width="33.3%"
                breakpoints={{
                  767: '50%',
                  479: '100%',
                }}>
                <C.BundleItem
                  variantId={bundle.shopify_product[0].variantId}
                  soldOut={bundle.soldOut}
                  slug={bundle.slug}
                  name={bundle.name}
                  price={bundle.price}
                  regularPrice={bundle.regularPrice}
                  image={bundle.mainImage.fluid.src}
                />
              </S.GridItem>
            ))}
          </S.Grid>
        </div>
      </S.Wrapper>
    </S.Section>
  )
}

export default Bundles
