import * as React from 'react'
import styled from 'styled-components'
import bg from '../assets/shop-intro-bg.jpg'
import * as S from '../styles'

const Container = styled(S.Section)`
  padding: 160px 0 60px 0;
  background: url(${bg}) center / cover no-repeat;
`

const IntroCapsules: React.SFC<{}> = () => {
  return (
    <Container>
      <S.Wrapper className="small">
        <div>
          <S.Heading as="h2" className="large">
            2020 might be the year to forget, but here are a few we remember
            fondly.
          </S.Heading>
          <S.Text style={{ color: 'white', marginBottom: '2em' }}>
            As part of our time capsule series, we're taking a look back at some
            excellent years.
          </S.Text>
        </div>
      </S.Wrapper>
    </Container>
  )
}

export default IntroCapsules
